* {
    font-family: 'Cutive Mono', monospace !important;
    text-transform: lowercase !important;
    font-size: 16px; 
}

body{
    background: white;
    color:#333333;
}

.inline{
    display:inline;
}

.block{
    display:block;
}

.icon-margin-left{
    margin-left: 2px;
}

.display-container{
    width: 100%;
    height: 100%;
    position:relative;
}

.display-container-asset{
    width: 101%;
    height: 101%;
    position: relative; 
    padding: 0px;
    margin: 0px;
}

